import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { AiSummaryBodyText } from "./AiSummaryBodyText";
import { AiSummaryContent } from "./AiSummaryContent";
import { AiSummaryFooter } from "./AiSummaryFooter";
import { AiSummaryFooterNote } from "./AiSummaryFooterNote";
import { AiSummaryHeader } from "./AiSummaryHeader";
import { CollapsedFooter } from "./CollapsedFooter";
import { AiSummaryProvider } from "./Context";
import { ProfileLink } from "./ProfileLink";
import { Section } from "./Section";
import { TextWithUserLinks } from "./TextWithUserLinks";

export interface AiSummaryProps {
  isCollapsed?: boolean;
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
  onExpandClick?: () => void;
  onCollapseClick?: () => void;
}

export const AiSummaryComponent = ({
  children,
  isCollapsed = false,
  className,
  onExpandClick,
  onCollapseClick,
}: AiSummaryProps) => (
  <AiSummaryProvider
    isCollapsed={isCollapsed}
    onCollapseClick={onCollapseClick}
  >
    <div
      className={classNames(
        "bg-primary group relative flex w-full overflow-hidden rounded-lg p-[2px] shadow-[0px_12px_16px_-4px_rgba(25,27,31,0.08),0px_4px_6px_-2px_rgba(25,27,31,0.03)] transition-all duration-500 before:absolute before:inset-0 before:bg-gradient-to-bl before:from-[#d4dbff] before:to-[#c6b3fa] before:transition-opacity before:duration-500 dark:shadow-[0_4px_6px_-1px_rgba(0,0,0,0.10),0_2px_4px_-2px_rgba(0,0,0,0.10)]",
        {
          "bg-gradient-to-bl from-[#eeeeee] to-[#dedede] before:opacity-0 hover:before:opacity-100 dark:from-[#3D3D3D] dark:to-[#3D3D3D]":
            isCollapsed,
        },
      )}
    >
      <div
        className={classNames(
          "bg-primary dark:bg-secondary relative flex h-full w-full flex-col gap-4 rounded-md px-5 pb-5 pt-4 transition-[padding] delay-200 duration-300 ease-in-out",
          {
            "group-hover:pb-14 group-hover:delay-0": isCollapsed,
          },
          className,
        )}
      >
        {children}
        {isCollapsed && onExpandClick && (
          <CollapsedFooter onExpandClick={onExpandClick} />
        )}
      </div>
    </div>
  </AiSummaryProvider>
);

export const AiSummary = Object.assign(AiSummaryComponent, {
  Content: AiSummaryContent,
  Footer: AiSummaryFooter,
  Header: AiSummaryHeader,
  BodyText: AiSummaryBodyText,
  FooterNote: AiSummaryFooterNote,
  TextWithUserLinks,
  ProfileLink,
  Section,
});
