import type { ReactNode } from "react";
import React from "react";
import { useEffect, useState } from "react";
import classNames from "classnames";
import type { IconType } from "@circle-react-shared/Icon";
import { RadioButton } from "./RadioButton";

export interface Option {
  value: string;
  label: ReactNode | string;
  icon?: IconType;
  iconSize?: number;
}

export interface RadioButtonGroupProps {
  name: string;
  options: Option[];
  defaultChecked: string;
  onChange?: (value: any) => void;
  size?: "sm" | "md";
  hasRadioCheckmarks?: boolean;
  shouldShowInlineOptions?: boolean;
  checkedOptionClassName?: string;
  Separator?: ReactNode;
}

export const RadioButtonGroup = ({
  name,
  options,
  defaultChecked,
  onChange,
  size = "md",
  hasRadioCheckmarks = false,
  shouldShowInlineOptions = false,
  checkedOptionClassName = "",
  Separator,
}: RadioButtonGroupProps) => {
  const [checkedOption, setCheckedOption] = useState(defaultChecked);

  useEffect(() => {
    if (checkedOption && onChange) {
      onChange(checkedOption);
    }
  }, [onChange, checkedOption]);

  return (
    <div
      className={classNames("flex", {
        "flex-col gap-3": !shouldShowInlineOptions,
        "flex-inline justify-center gap-4 text-center": shouldShowInlineOptions,
      })}
    >
      {options.map(({ value, label, icon, iconSize }, index) => (
        <React.Fragment key={value}>
          <RadioButton
            data-testid={`radio-button-group-${index}`}
            isChecked={value === checkedOption}
            onChange={setCheckedOption}
            label={label}
            name={name}
            id={`${name}-${index}`}
            value={value}
            size={size}
            hasRadioCheckmarks={hasRadioCheckmarks}
            icon={icon}
            iconSize={iconSize}
            checkedOptionClassName={checkedOptionClassName}
          />
          {index < options.length - 1 && Separator}
        </React.Fragment>
      ))}
    </div>
  );
};
