import { useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { TextWithUserLinks } from "./TextWithUserLinks";

interface SectionProps {
  title: string;
  text: string;
}

export const Section = ({ title, text }: SectionProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="bg-secondary mt-2 rounded-2xl px-4 py-3">
      <button
        type="button"
        onClick={() => setIsExpanded(!isExpanded)}
        className="flex w-full items-center justify-between"
      >
        <Typography.BodySm weight="medium" as="strong">
          {title}
        </Typography.BodySm>
        <ChevronDownIcon
          className={classNames(
            "text-secondary h-5 w-5 transition-transform",
            isExpanded ? "rotate-180" : "",
          )}
        />
      </button>
      <div
        className={classNames(
          "text-primary mt-2",
          !isExpanded && "line-clamp-4",
        )}
      >
        <TextWithUserLinks text={text} />
      </div>
    </div>
  );
};
